<template>
  <div>Metadata Maintenance<router-view /></div>

</template>

<script>
export default {
name: "metadataMaintenance"
}
</script>

<style scoped>

</style>